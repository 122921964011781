<template>
  <div class="check-record list-page">
    <div class="ab-setup">
      <el-button type="text" :class="{'active': isABFlag == '1'}" @click="changeABFlag('1')">AB套工具</el-button>
      <el-button type="text" :class="{'active': isABFlag == '0'}" @click="changeABFlag('0')">非AB套工具</el-button>
    </div>

    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="6">
          <el-form-item label="点检频率" prop="tpmRate">
            <el-select v-model="formData.tpmRate" placeholder="请选择" @change="formData.tpmTime = ''">
              <el-option
                  v-for="item in tpmRateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="点检时间" prop="tpmTime">
            <el-date-picker
                v-model="formData.tpmTime"
                :type="formData.tpmRate == 'MONTH' ? 'month' : 'date'"
                :value-format="formData.tpmRate == 'MONTH' ? 'YYYY-MM' : 'YYYY-MM-DD'"
                placeholder="请选择点检时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label="资产编号" prop="assetCode">
            <el-input v-model="formData.assetCode" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="12" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
          <el-button class="search active" @click="handleExport()">导出</el-button>
          <el-button class="search active" @click="handleDownload()">下载报表</el-button>
        </el-col>
      </el-form>
    </div>

    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果统计</h1>
          <p>Query result statistics</p>
        </div>
      </div>
      <ul class="statistics-box" id="statisticsBox">
        <li v-for="(item, idx) in statisticsData" :key="idx" class="statistics-box-item">
          <div class="statistics-box-item-box">
            <h2>{{ item.assetTypeName }}</h2>
            <div class="chart-box" :id="'echartId' + idx"></div>
            <p>总数<span>{{ item.total }}</span></p>
            <p>正常<span>{{ item.ok }}</span></p>
            <p>异常<span class="warning">{{ item.abnormal }}</span></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="code" label="资产编号"></el-table-column>
        <el-table-column prop="name" label="资产名称" min-width="100"></el-table-column>
        <el-table-column prop="typeName" label="资产类型" width="100"></el-table-column>
        <el-table-column prop="workStationName" label="工段" width="100"></el-table-column>
        <el-table-column prop="empStationName" label="工位" width="100"></el-table-column>
        <!-- <el-table-column prop="status" label="资产状态" width="80" :formatter="formatterStatus"></el-table-column> -->
        <el-table-column prop="tpmStatus" label="点检状态" width="100" :formatter="formatterTpmStatus">
          <template #default="{row, column}">
            <span
                :class="{'warning': row.tpmStatus != 'OK'}">{{ formatterTpmStatus(row, column, row.tpmStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作员" width="120"></el-table-column>
        <!--        <el-table-column prop="operatorWorkStationName" label="操作工段" width="80"></el-table-column>-->
        <el-table-column prop="lastTpmTime" label="点检时间" width="140"></el-table-column>
        <el-table-column label="操作" width="80" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleDetail(row)">详情</el-button>
            <!-- <el-button @click="handleInvalid(row)" class="del">作废</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            @current-change="handleCurrentPage"
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            layout="prev, pager, next"
            :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情弹框 -->
    <!-- <el-dialog v-model="detailDialogVisible" width="600px" @close="detailDialogVisible = false">
      <template #title>
        <h1>详细信息</h1>
        <p>details</p>
      </template>
      <div class="detail-content">
        <ul class="detail-list">
          <li>
            <label>资产编号</label>
            <span>{{detailObj.code}}</span>
          </li>
          <li>
            <label>资产名称</label>
            <span>{{detailObj.name}}</span>
          </li>
          <li>
            <label>资产类型</label>
            <span>{{detailObj.typeName}}</span>
          </li>
          <li>
            <label>工段</label>
            <span>{{detailObj.workStationName}}</span>
          </li>
          <li>
            <label>工位</label>
            <span>{{detailObj.empStationName}}</span>
          </li>
          <li>
            <label>点检时间</label>
            <span>{{detailObj.lastTpmTime}}</span>
          </li>
          <li>
            <label>操作员</label>
            <span>{{detailObj.operator}}</span>
          </li>
          <li>
            <label>操作工段</label>
            <span>{{detailObj.operatorWorkStationName}}</span>
          </li>
          <li>
            <label>点检结果</label>
            <div class="result" v-if="detailObj.options && detailObj.options.length > 0">
              <span :class="['result-opt', {'warning': detailObj.options[0].tpmOptionCode == 'ABNORMAL'}]" v-if="detailObj.options[0]">{{detailObj.options[0].tpmItemName}} {{detailObj.options[0].tpmOptionName}}</span>
              <span :class="['result-opt', {'warning': detailObj.options[1].tpmOptionCode == 'ABNORMAL'}]" v-if="detailObj.options[1]">{{detailObj.options[1].tpmItemName}} {{detailObj.options[1].tpmOptionName}}</span>
              <el-button v-if="detailObj.options.length > 2" class="more-btn" @click="showMore = !showMore">更多<i v-if="!showMore" class="el-icon-arrow-down"></i><i v-if="showMore" class="el-icon-arrow-up"></i></el-button>
            </div>
          </li>
        </ul>
        <div class="result-more" v-if="showMore">
          <ul class="detail-list">
            <li v-for="item in detailObj.options" :key="item.id">
              <label>{{item.tpmItemName}}</label>
              <span>{{item.tpmOptionName}}</span>
            </li>
          </ul>
        </div>  
      </div>
      <template #footer>
        <el-button @click="detailDialogVisible = false" class="active">关 闭</el-button>
      </template>
    </el-dialog> -->

    <!-- 详情弹框_20220424 -->
    <el-dialog v-model="detailDialogVisible" width="800px" @close="detailDialogVisible = false">
      <template #title>
        <h1>详细信息</h1>
        <p>details</p>
      </template>
      <el-table :data="detailTableData">
        <el-table-column prop="tpmItemName" label="点检项"></el-table-column>
        <el-table-column prop="formType" label="表单类型" :formatter="formatterFormType"></el-table-column>
        <el-table-column prop="inputStandardValue" label="标准值"></el-table-column>
        <el-table-column label="输入值/选择值">
          <template #default="{row}">
            <span>{{ row.formType === 'INPUT' ? row.inputValue : row.tpmOptionCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="点检结果">
          <template #default="{row}">
            <span>{{ getUserTpmValue(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="{row, column}">
            <span
                :class="{'warning': row.tpmStatus != 'OK'}">{{ formatterTpmResult(row, column, row.tpmStatus) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="detailDialogVisible = false" class="active">关 闭</el-button>
      </template>
    </el-dialog>

    <!-- 下载报表弹框 -->
    <el-dialog v-model="downloadDialogVisible" width="550px" @close="cancelDownload('downloadFormRef')">
      <template #title>
        <h1>下载报表</h1>
        <p>Download Report</p>
      </template>
      <el-form :model="downloadFormData" ref="downloadFormRef" class="download-form" :rules="downloadFormRules">
        <!--        <el-form-item label="点检频率" prop="tpmRate">
                  <el-select v-model="downloadFormData.tpmRate" placeholder="请选择" @change="changeTpmRate">
                    <el-option
                      v-for="item in tpmRateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="资产类型" prop="assetType">
                  <el-select v-model="downloadFormData.assetType" placeholder="请选择">
                    <el-option
                      v-for="item in assetTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="时间段" prop="daterange">
                  <el-date-picker
                    v-model="downloadFormData.daterange"
                    :type="downloadFormData.tpmRate == 'MONTH' ? 'monthrange' : 'daterange'"
                    :value-format="downloadFormData.tpmRate == 'MONTH' ? 'YYYY-MM' : 'YYYY-MM-DD'">
                  </el-date-picker>
                </el-form-item>-->
        <el-form-item label="点检时间" prop="tpmTime">
          <el-date-picker
              v-model="downloadFormData.tpmTime"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="AB班次" prop="classShift">
          <el-select v-model="downloadFormData.classShift" placeholder="请选择">
            <el-option
                v-for="item in classShiftList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <template #footer>
        <el-button @click="cancelDownload('downloadFormRef')">取 消</el-button>
        <el-button @click="confirmDownload('downloadFormRef')" class="active">下 载</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {record, common} from '@/api'

export default {
  name: 'CheckRecord',
  data() {
    return {
      isABFlag: '1',

      statisticsData: [], // 统计信息
      colorList: [ // 颜色列表
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#7DA4F4'}, {offset: 1, color: '#476BE5'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#8982FF'}, {offset: 1, color: '#4B43DF'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#82F7FF'}, {offset: 1, color: '#0BA3B5'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#C582FF'}, {offset: 1, color: '#8C43DF'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#FFE382'}, {offset: 1, color: '#CDAA21'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#7DF48F'}, {offset: 1, color: '#47E54C'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#FFBC82'}, {offset: 1, color: '#EB6209'}]),
      ],

      formData: { // 查询表单
        tpmRate: '',
        tpmTime: '',
        // assetCode: '',
      },
      statusList: [], // 资产状态列表
      tpmStatusList: [], // 点检状态列表
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },

      detailDialogVisible: false, // 详情弹框visible
      detailObj: {}, // 详情信息
      showMore: false,
      detailTableData: [], // 详情

      downloadDialogVisible: false, // 下载弹框visible
      downloadFormData: { // 下载表单
        classShift: '',
        tpmTime: ''
      },

      downloadFormRules: { // 修改表单的校验
        classShift: {required: true, message: '请选择', trigger: 'blur'},
        tpmTime: {required: true, message: '请选择', trigger: 'blur'}
      },

      tpmRateList: [], // 点检频率列表
      assetTypeList: [], // 资产类型列表
      formTypeList: [
        {
          label: '选择',
          value: 'SELECT'
        },
        {
          label: '输入',
          value: 'INPUT'
        }
      ],
      tpmOptions: [
        {
          label: 'OK',
          value: 'OK'
        },
        {
          label: 'NOK',
          value: 'NOK'
        },
        {
          label: 'N/A',
          value: 'N/A'
        }
      ],
      classShiftList: [
        {
          label: 'A',
          value: 'A'
        },
        {
          label: 'B',
          value: 'B'
        }
      ]
    }
  },
  created() {
    // this.formData.tpmTime = this.formatDate(new Date(), 'yyyy-MM-dd')
    this.getDict()
    this.loadTableData()
  },
  mounted() {

    this.getRecordStatistics()
    // this.getTpmRateList()
  },
  methods: {

    formatDate(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    changeABFlag(flag) {
      if (this.isABFlag != flag) {
        this.isABFlag = flag
        this.handleReset('formRef')
      }
    },
    // 获取字典数据
    async getDict() {
      let types = 'ASSET_STATUS,TPM_STATUS,TPM_RATE'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.statusList = data.ASSET_STATUS || []
        this.tpmStatusList = data.TPM_STATUS || []
        this.tpmRateList = data.TPM_RATE || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取点检频率列表
    async getTpmRateList() {
      let types = 'TPM_RATE'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.tpmRateList = data.TPM_RATE || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取资产类型列表
    async getAssetTypeList(type) {
      const {code, msg, data} = await common.queryAssetTypeList(type)
      if (code === 0) {
        this.assetTypeList = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 改变点检频率
    changeTpmRate(val) {
      this.downloadFormData.daterange = [] // 清空时间段值
      this.getAssetTypeList(val)
    },
    async getRecordStatistics() {
      let params = {
        ...this.formData,
        abFlag: this.isABFlag,
      }
      const {code, msg, data = []} = await record.getRecordStatistics(params)
      if (code === 0) {
        this.statisticsData = data

        // 画图
        this.$nextTick(() => {
          data.forEach((item, idx) => {
            let okVal = item.ok
            let notOk = okVal ? item.total - item.ok : 0
            let perVal = okVal ? Math.ceil(okVal / item.total * 100) : 0

            let boxW = document.getElementById('statisticsBox')
            let charDom = document.getElementsByClassName('statistics-box-item')
            charDom[idx].style.width = Math.floor(boxW.offsetWidth / 7) + 'px'

            let myChart = echarts.init(document.getElementById('echartId' + idx))
            myChart.setOption({
              series: [{
                type: 'pie',
                radius: ['40%', '70%'],
                color: [okVal ? this.colorList[idx] : '#2c2c42', '#2c2c42'],
                labelLine: {
                  show: false
                },
                label: {
                  show: true,
                  position: 'center',
                  fontSize: '12px',
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  color: '#B4B4C5',
                  formatter: `${perVal}%`
                },
                data: [
                  {value: okVal, name: '正常'},
                  {value: notOk, name: '非正常'},
                ]
              }]
            })
          })
        })
      } else {
        this.$message.error(msg)
      }
    },
    /*  // 格式化资产状态
      formatterStatus(row, column, cellValue) {
        let label = cellValue
        for(let item of this.statusList) {
          if(item.value == cellValue){
            label = item.label
            continue
          }
        }
        return label
      },*/
    // 格式化表单类型
    formatterFormType(row, column, cellValue) {
      let label = cellValue
      for (let item of this.formTypeList) {
        if (item.value == cellValue) {
          label = item.label
          continue
        }
      }
      return label
    },
    // 格式化点检状态
    formatterTpmStatus(row, column, cellValue) {
      return cellValue === 'OK' ? '正常' : '异常';
    },
    // 格式化点检
    formatterTpmOption(value) {
      let label = {};
      for (let item of this.tpmOptions) {
        if (item.value === value) {
          label = item;
          break;
        }
      }
      return label
    },
    getUserTpmValue(row) {
      const formType = row.formType;

      const tpmValue = 'INPUT' === formType ? row.inputValue : row.tpmOptionCode;
      if (tpmValue === '') {
        return '未点检';
      }

      return 'INPUT' === formType ? (tpmValue == row.inputStandardValue ? 'OK' : 'NOK') : this.formatterTpmOption(tpmValue).label
    },

    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        abFlag: this.isABFlag,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await record.getRecordList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.getRecordStatistics()
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.getRecordStatistics()
      this.loadTableData()
    },
    // 导出
    async handleExport() {
      const loading = this.$loading({
        lock: true,
        text: '导出的数据较大，请等待...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        ...this.formData,
        abFlag: this.isABFlag,
      };
      try {
        await common.downloadFile('/asset/tpm/export', null, params)
      } catch (e) {
        console.log(e);
      } finally {
        loading.close();
      }
    },
    // 下载
    async handleDownload() {
      this.downloadDialogVisible = true
    },
    // 确认下载
    confirmDownload(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '下载的数据较大，请等待...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          try {
            let params = {
              ...this.downloadFormData,
            };
            await common.downloadFile('/asset/report', null, params)
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              loading.close();
            }, 1500);
          }
        }
      })
    },
    // 取消下载
    cancelDownload(formName) {
      this.$refs[formName].resetFields()
      this.downloadDialogVisible = false
    },
    // 详情
    async handleDetail({id}) {
      const {code, msg, data} = await record.getRecordDetail(id)
      if (code === 0) {
        // this.detailObj = data || {}
        let options = data && data.options
        this.detailTableData = options || []
        this.detailDialogVisible = true
      } else {
        this.$message.error(msg)
      }
    },
    // 格式化点检结果
    formatterTpmResult(row, column, cellValue) {
      if (cellValue === 'ABNORMAL') {
        return '异常'
      }
      if (cellValue === 'OK') {
        return '正常'
      }
      return cellValue
    },
    // 作废
    async handleInvalid({id}) {
      this.$confirm('确认进行此操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await record.invalidRecord({id})
        if (code === 0) {
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消作废操作')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.check-record {
  .ab-setup {
    border-bottom: 1px solid rgba(95, 95, 144, 0.7);
    margin-bottom: 20px;

    .el-button {
      background: rgba(95, 95, 144, 0.5);
      min-width: 200px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: #fff;
      font-size: 18px;
      padding: 4px 0;

      &.active {
        background: rgb(95, 95, 144);
      }
    }
  }

  .search-box {
    .body.el-row {
      .search-btn-box {
        .el-button + .el-button {
          margin-left: 1vw;
        }
      }
    }
  }

  .list-box {
    .statistics-box {
      list-style: none;
      display: flex;
      width: 100%;
      margin-top: 20px;
      overflow-x: auto;
      overflow-y: hidden;

      & > li {
        padding-right: 25px;
        flex-shrink: 0;

        .statistics-box-item-box {
          padding: 1.1vw;
          background: #101018;
          border-radius: 16px;
          position: relative;
          color: #B4B4C5;
          font-size: 14px;

          &:not(:first-of-type) {
            margin-left: 25px;
          }

          h2 {
            font-weight: normal;
            font-size: 14px;
          }

          .chart-box {
            width: 100%;
            height: 100px;
          }

          p {
            & > span {
              font-size: 18px;
              color: #FFFFFF;
              margin-left: 14px;
              font-weight: bold;

              &.warning {
                color: #DF3944;
              }
            }

            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .detail-content {
    position: relative;
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;

    .detail-list {
      list-style: none;
      padding-bottom: 30px;

      & > li {
        border-bottom: 1px solid rgba($color: #979797, $alpha: 0.3);
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;

        & > label {
          font-size: 14px;
          color: #B4B4C5;
        }

        & > span {
          font-size: 16px;
          color: #fff;
        }

        .result {
          font-size: 14px;
          color: #fff;

          .result-opt {
            &:not(:first-of-type) {
              margin-left: 15px;
            }

            &.warning {
              color: #DF3944;
            }
          }

          .more-btn {
            margin-left: 15px;
            padding-top: 8px;
            padding-bottom: 8px;
            min-height: auto;
          }
        }
      }
    }

    .result-more {
      position: absolute;
      bottom: 86px;
      right: 32px;
      width: 280px;
      background: linear-gradient(181deg, #2E2E44 0%, #1B1B27 100%);
      box-shadow: 0px 7px 17px -2px rgba(9, 9, 13, 0.96);
      border-radius: 8px;
      padding: 20px;

      .detail-list {
        padding-bottom: 10px;

        & > li {
          padding: 0;
        }
      }
    }
  }

  .download-form {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    padding: 30px 30px 10px;

    .el-form-item {
      ::v-deep .el-form-item__label {
        width: 100px;
        color: #B4B4C5;
      }

      ::v-deep .el-form-item__content {
        .el-select {
          width: 100%;

          .el-select__tags .el-tag {
            background-color: #2e2e41;

            .el-icon-close {
              background-color: #1a1a25;
            }
          }
        }

        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
</style>